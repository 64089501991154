$(function() {

  // preloader
  require('./modules/preloader')();

  // top-menu__icon hamburger
  require('./modules/burger')();

  // sticky header
  require('./modules/sticky-header')();

  // lightbox
  require('./modules/lightbox')();

  // SVG for IE
  require('./modules/svg4everybody')();

  // main menu
  require('./modules/menu')();

  // index top-slider
  require('./modules/top-slider')();

  // index partners-slider
  require('./modules/partners-slider')();

  // newslist-filter
  require('./modules/newslist-filter')();

  // select UI
  require('./modules/select-ui.js')();

  // accordeon
  require('./modules/accordeon-price.js')();

  // catalog-one-slider
  require('./modules/catalog-one-slider.js')();

  // object-one-slider
  require('./modules/object-one-slider.js')();

  //google-map
  // require('./modules/google-map')();

  //contacts-map
  // require('./modules/contacts-map')();

});