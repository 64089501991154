module.exports = function() {

  //custom selectbox
  $(function() {
    $( ".selectmenu" ).selectmenu();

    $( "#certificate-select-01" ).selectmenu({
      change: function(event, ui) {
        var sertificateImg = $(ui.item.element[0]).data('path');
        $('#certificate__pic-01').find('img:first').attr('src', sertificateImg);
        // console.log($(ui.item.element[0]).data('path'));
      }
    });
    $( "#certificate-select-02" ).selectmenu({
      change: function(event, ui) {
        var sertificateImg = $(ui.item.element[0]).data('path');
        $('#certificate__pic-02').find('img:first').attr('src', sertificateImg);
        // console.log($(ui.item.element[0]).data('path'));
      }
    });
    $( "#certificate-select-03" ).selectmenu({
      change: function(event, ui) {
        var sertificateImg = $(ui.item.element[0]).data('path');
        $('#certificate__pic-03').find('img:first').attr('src', sertificateImg);
        // console.log($(ui.item.element[0]).data('path'));
      }
    });
    $( "#certificate-select-04" ).selectmenu({
      change: function(event, ui) {
        var sertificateImg = $(ui.item.element[0]).data('path');
        $('#certificate__pic-04').find('img:first').attr('src', sertificateImg);
        // console.log($(ui.item.element[0]).data('path'));
      }
    });
    $( "#certificate-select-05" ).selectmenu({
      change: function(event, ui) {
        var sertificateImg = $(ui.item.element[0]).data('path');
        $('#certificate__pic-05').find('img:first').attr('src', sertificateImg);
        // console.log($(ui.item.element[0]).data('path'));
      }
    });
    $( "#certificate-select-06" ).selectmenu({
      change: function(event, ui) {
        var sertificateImg = $(ui.item.element[0]).data('path');
        $('#certificate__pic-06').find('img:first').attr('src', sertificateImg);
        // console.log($(ui.item.element[0]).data('path'));
      }
    });
  });

};