module.exports = function() {

  $(function() {
    $('.partners-slider__list').bxSlider({
      minSlides: 1,
      maxSlides: 5,
      moveSlides: 1,
      slideWidth: 220,
      autoHover: true,
      pager: false
    });
  });

};