module.exports = function() {

  //accrordeon for price&service
  $(function($){
    var contents = $('.accordeon-content');
    var titles = $('.accordeon-title__click');
    titles.on('click',function(){

      var title = $(this);

      $(this).closest('.accordeon__item').toggleClass('active');
      contents.filter(':visible').slideUp(function(){
        $(this).prev('.accordeon-title').removeClass('is-opened');
      });

      var titleParent = title.parent();
      var content = titleParent.next('.accordeon-content');

      if (!content.is(':visible')) {
        content.slideDown(function(){titleParent.addClass('is-opened')});
      }
    });
  });

    $('.catalog-left__header--hide').on('click', function () {
        $(this).siblings('.catalog-left__list--hide').slideToggle();
        $(this).toggleClass('is-opened');
    });

};