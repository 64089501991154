module.exports = function() {

  $(function() {
    $('.top-slider__list').bxSlider({
      mode: 'fade',
      pager: false,
      auto: true,
    });
  });

};