module.exports = function() {

  $("ul.sf-menu").superfish({
    delay: 200,
    speed: "fast",
    cssArrows: false
  });

  $("#menu").mmenu({
    extensions    : [ 'widescreen', 'theme-white', 'effect-menu-slide', 'pagedim-black' ],
    navbar: {
      title: "Меню"
    }
  });

  $(".toggle-mnu").on('click', function() {
    $(this).toggleClass("on");
  });
  var api = $("#menu").data("mmenu");
  api.bind("closed", function () {
      $(".toggle-mnu").removeClass("on");
  });

};